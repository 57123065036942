import React from 'react';
import Typography from '@material-ui/core/Typography';
import GtagSend from '../../../../hooks/gtagSend';

const Contact = (props) => {
    GtagSend({'page_title':'Contacto'});

    return (
    <React.Fragment>
        <Typography component="h1" variant="h5">Contacto</Typography>
        <Typography component="p" variant="body1">+34 93 380 1550</Typography>
    </React.Fragment>
    );

}
export default Contact;
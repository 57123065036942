import React from 'react';
import Container from '@material-ui/core/Container';
import LoginForm from './LoginForm/LoginForm';
import Contact from './Contact/Contact';


import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NavBar from '../Header/Navbar/Navbar';

const MainContent = () => {
    const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        navDisplayFlex: {
          display: 'flex',
          justifyContent: 'space-between'
        },
        linkText: {
          textDecoration: `none`,
          textTransform: `uppercase`,
          color: `white`
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        }
      }));
      const classes = useStyles();
    return (
      <BrowserRouter basename="/">
        <NavBar />
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Switch>
          <Route path = "/acceso" exact>
          <LoginForm />
          </Route>
          <Route path = "/contacto" exact>
            <Contact />
          </Route>
          <Route path = "/" exact>
          <Typography component="h1" variant="h5">HOLA!</Typography>
          <Typography component="p" variant="body1">Estamos en ello! En unos dias lanzamos la web!</Typography>
          </Route>
          </Switch>
      </Container>
      </BrowserRouter>
   );
};

export default MainContent;
import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {Button} from '@material-ui/core';
import {makeStyles } from '@material-ui/core/styles';
import GtagSend from '../../../../hooks/gtagSend';

const LoginForm = (props) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        navDisplayFlex: {
          display: 'flex',
          justifyContent: 'space-between'
        },
        linkText: {
          textDecoration: `none`,
          textTransform: `uppercase`,
          color: `white`
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        }
      }));
      const classes = useStyles();
      GtagSend({'page_title':'Acceso cliente'});

    return (
    <React.Fragment>
        <Typography component="h1" variant="h5">Acceso cliente</Typography>
    <form className={classes.form} noValidate>
      <TextField label="Username" variant="filled" fullWidth ></TextField>
      <TextField label="Password" variant="filled" fullWidth ></TextField>
      <Button 
      fullWidth
      type="submit"
      variant="contained"
      color="primary"
      className={classes.submit}
    > login </Button>
    </form>
    </React.Fragment>
   );
}

export default LoginForm;